import React from 'react';
import numeral from 'numeral';
import FindHighestLTV from './FindHighestLTV';
import FindLoanLimits from './FindLoanLimits';
import FindLoanAmountType from './FindLoanAmountType';
import { Input, Tooltip } from '@capcenter/shared-legacy';

// TURN THIS ON WHEN DEBUGGING
const isDebug = false;

export default class LoanAmount extends React.Component<{
  active: string;
  onChange: Function;
  fields: any;
  label: string | JSX.Element;
  inputKey: string;
  disabled: Boolean;
  invalid: Boolean;
  availability: any;
  exceptions: any;
}> {
  readonly state = {
    invalidHigh: false,
    invalidLow: false,
    tempValue: '',
    tempCount: 0,
    value: '',
    percent: '',
    maxLoanAmount: 0,
    minLoanAmount: 0
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const previousCountyId = prevProps.availability?.CountyId;
    const countyId = this.props.availability?.CountyId;

    // If Initial API data load is done
    if (
      Object.keys(prevProps.availability).length === 0 &&
      Object.keys(this.props.availability).length !== 0 &&
      this.props.active !== ''
    ) {
      this.changeValue(this.convertDollar(this.props.active));
      this.changePercent(this.dollarToPercent(this.convertDollar(this.props.active)) + '%')
    } else if (Object.keys(this.props.availability).length !== 0) {
      // If Purchase, Check Sister Amount
      if (
        this.props.fields.LoanPurpose === 'Purchase' &&
        this.props.fields.DownPayment !== prevProps.fields.DownPayment &&
        this.props.disabled !== true &&
        this.props.fields.PurchasePrice !== '' &&
        this.props.fields.DownPayment !== ''
      ) {
        const dollarAmount = this.convertDollar(this.props.fields.PurchasePrice) - this.convertDollar(this.props.fields.DownPayment)
        this.changeValue(dollarAmount);
        this.changePercent(this.dollarToPercent(dollarAmount) + '%');
      } else if (
        JSON.stringify(this.props.fields) !== JSON.stringify(prevProps.fields) &&
        this.props.fields.PurchasePrice !== '' &&
        this.props.disabled !== true &&
        this.state.value !== ''
      ) {
        const updatedLoanAmountValue = this.state.tempValue ? this.state.tempValue : this.state.value;
        this.changeValue(this.convertDollar(updatedLoanAmountValue));
        this.changePercent(this.dollarToPercent(this.convertDollar(updatedLoanAmountValue)) + '%');

        if (this.props.fields.LoanPurpose === 'Refinance') { // If Refinance, also update down payment
          this.props.fields.DownPayment =
            this.convertDollar(this.props.fields.PurchasePrice) - this.convertDollar(updatedLoanAmountValue);
        }
      }
    } else if (previousCountyId !== countyId) {
      this.changeValue(this.convertDollar(this.props.active));
      this.changePercent(this.dollarToPercent(this.convertDollar(this.props.active)) + '%');
    }

    if (
      Object.keys(this.props.availability).length !== 0 &&
      this.props.fields.PurchasePrice !== '' &&
      this.props.fields.DownPayment !== '' &&
      this.props.disabled !== true &&
      this.props.fields.LoanAmount === '' &&
      JSON.stringify(this.props.fields) !== JSON.stringify(prevProps.fields)
      
    ){
      this.changeInvalidHighLow();
    }

    if (
      Object.keys(this.props.availability).length !== 0 &&
      this.props.fields.PurchasePrice !== '' &&
      this.props.fields.DownPayment !== '' &&
      this.props.disabled !== true &&
      this.props.fields.FirstTimeHomeBuyer !=='' &&
      (this.props.fields.FirstTimeHomeBuyer === 'true' || this.props.fields.FirstTimeHomeBuyer === true) &&
      this.state.invalidHigh === true
    ){
      this.changeInvalidHighLow();
    }   

    if (
      Object.keys(this.props.availability).length !== 0 &&
      this.props.fields.PurchasePrice !== '' &&
      this.props.fields.DownPayment !== '' &&
      this.props.disabled !== true &&
      this.props.fields.FirstTimeHomeBuyer !=='' &&
      (this.props.fields.FirstTimeHomeBuyer === 'true' || this.props.fields.FirstTimeHomeBuyer === true) &&
      this.props.fields.PropertyType !== '' &&
      (this.props.fields.PropertyType === 'Single-wide Manufactured Home' || this.props.fields.PropertyType === 'Double-wide Manufactured Home') &&
      this.state.invalidHigh === false
    ){
      const updatedLoanAmountValue = this.state.tempValue ? this.state.tempValue : this.state.value;
      this.changeValue(this.convertDollar(updatedLoanAmountValue));
    }   
  }

  dollarToPercent = (dollar: number) => {
    return ((dollar * 100) / parseInt(this.props.fields.PurchasePrice)).toFixed(2).toString()
  }

  convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

  findMinMaxAmounts = (fields: any) => {
    const { loanMinLimit, loanMaxLimit, showLtvMaxLimitInsteadOfLoanMaxLimit } = FindLoanLimits(
      fields,
      this.props.availability.ProductCombinations,
      this.props.exceptions,
      this.props.availability.LoanAmountLimits,
    );

    const minAmount = loanMinLimit;
    const maxAmount = loanMaxLimit;

    return { minAmount, maxAmount, showLtvMaxLimitInsteadOfLoanMaxLimit };
  };

  onChange = (value: number) => {
    const thisTempCount = this.state.tempCount + 1;

    this.setState({ tempCount: thisTempCount, tempValue: value.toString() });

    setTimeout(() => {
      if (thisTempCount.toString() === this.state.tempCount.toString()) {
        this.changeValue(value);
        const percent = this.dollarToPercent(value);
        this.changePercent(percent + '%');
      }
    }, 400);
  };

  changeInvalidHighLow = () => {
    let fields = JSON.parse(JSON.stringify(this.props.fields));
    const loanAmount = this.convertDollar(this.state.tempValue ? this.state.tempValue : this.state.value);
    const minAmount = this.state.minLoanAmount;
    const maxAmount = this.state.maxLoanAmount;

    if (loanAmount <= maxAmount && loanAmount >= minAmount) {
      this.changeValue(loanAmount)
      this.state.invalidHigh = false;
      this.state.invalidLow = false;
    }
    else if (loanAmount > maxAmount) {
      this.state.invalidHigh = true;
      this.state.invalidLow = false;
    } 
    else {
      this.state.invalidHigh = false;
      this.state.invalidLow = true;
    }
  }

  changeValue = (value: number) => {
    let fields = JSON.parse(JSON.stringify(this.props.fields));

    fields.LoanAmountType = FindLoanAmountType(value.toString(), this.props.availability.LoanAmountLimits);

    const minAmount = this.state.minLoanAmount;
    const maxAmount = this.state.maxLoanAmount;
    
    // Create current state object for comparison
    const currentState = {
      tempValue: this.state.tempValue,
      invalidHigh: this.state.invalidHigh,
      invalidLow: this.state.invalidLow,
      value: this.state.value,
    };

    if (value <= maxAmount && value >= minAmount) {
      const newState = {
        tempValue: '',
        invalidHigh: false,
        invalidLow: false,
        value: value.toString(),
      };

      if (JSON.stringify(newState) !== JSON.stringify(currentState)) {
        this.setState(newState, this.props.onChange(value.toString(), this.props));
      }
    } else {
      if (value > maxAmount) {
        const newState = {
          tempValue: value.toString(),
          invalidHigh: true,
          invalidLow: false,
          value: '',
        };
        if (JSON.stringify(newState) !== JSON.stringify(currentState)) {
          this.setState(newState, this.props.onChange('', this.props));
        }
      } else {
        const newState = {
          tempValue: value.toString(),
          invalidHigh: false,
          invalidLow: true,
          value: '',
        };
        if (JSON.stringify(newState) !== JSON.stringify(currentState)) {
          this.setState(newState, this.props.onChange('', this.props));
        }
      }
    }
  };

  convertPercent = (value: string) => {
    let valInt;

    valInt = value.replace(/[^\d.-]/g, '');

    return parseFloat(valInt);
  };

  onChangePercent = (value: string) => {
    this.changePercent(value);
    const percent: any = this.convertPercent(value);
    if (!Number.isNaN(percent)) {
      if (value === '') {
        this.changeValue(0);
        this.changePercent('');
      } else {
        const dollarAmount = Math.round((percent / 100) * parseInt(this.props.fields.PurchasePrice));
        this.changeValue(dollarAmount);
      }
    }
  };

  changePercent = (percent: string) => {
    if (percent !== this.state.percent) {
      this.setState({
        percent: percent
      })
    }
  };

  render() {
    if (Object.keys(this.props.availability).length !== 0 && this.props.disabled !== true) {
      let fields = JSON.parse(JSON.stringify(this.props.fields));

      if (this.state.invalidHigh || this.state.invalidLow) {
        fields.LoanAmount = this.state.tempValue.toString();
      }
      else {
        fields.LoanAmount = this.state.value.toString();
      }

      fields.LoanAmountType = FindLoanAmountType(fields.LoanAmount, this.props.availability.LoanAmountLimits);

      const highestLTV = FindHighestLTV(this.props.availability.ProductCombinations, fields, false);

      const { minAmount, maxAmount, showLtvMaxLimitInsteadOfLoanMaxLimit } = this.findMinMaxAmounts(fields);

      if (this.state.maxLoanAmount !== maxAmount || this.state.minLoanAmount !== minAmount){
        this.setState({
          maxLoanAmount: maxAmount,
          minLoanAmount: minAmount
        })
      }

      if (
        (this.state.invalidHigh === true && this.convertDollar(this.state.tempValue ? this.state.tempValue : this.state.value) <= this.state.maxLoanAmount) ||
        (this.state.invalidLow === true && this.convertDollar(this.state.tempValue ? this.state.tempValue : this.state.value) >= this.state.minLoanAmount) || 
        (this.state.invalidHigh === false && this.convertDollar(this.state.tempValue ? this.state.tempValue : this.state.value) > this.state.maxLoanAmount) ||
        (this.state.invalidLow === false && this.convertDollar(this.state.tempValue ? this.state.tempValue : this.state.value) < this.state.minLoanAmount)
      ){
        this.changeInvalidHighLow();
      }

      let minPercent: any = (minAmount * 100) / parseInt(fields.PurchasePrice);
      let maxPercent: any = (maxAmount * 100) / parseInt(fields.PurchasePrice);
      if (maxPercent > 100) {
        maxPercent = 100;
      }

      return (
        <>
          <div className="form-row">
            <div className="col-12">
              <label className="mb-1 bold" htmlFor={'quote--LoanAmount'}>{
                <>
                  {this.props.label}
                  {isDebug &&
                  ' - ( $' +
                  numeral(minAmount).format('0a') +
                  ' - $' +
                  numeral(maxAmount).format('0a') +
                  ' or ' +
                  (minPercent && minPercent.toString().substr(0, 8)) +
                  '% - ' +
                  (maxPercent && maxPercent.toString().substr(0, 8)) +
                  '% ) '}
                </>
              }</label>
            </div>
          </div>
          <div className="form-row">
            <div className="col-8">
              <Tooltip
                html={<div />}
                error={true}
                distance={10}
                errorHtml={
                  <span>
                    {this.state.invalidLow ? (
                      <>
                        Amount is lower than ${numeral(minAmount).format('0,0')}
                        <br />
                        For lower amounts, Call Us
                      </>
                    ) : (
                      <></>
                    )}

                    {(this.state.invalidHigh && fields.LoanAmountType === 'Jumbo' && showLtvMaxLimitInsteadOfLoanMaxLimit === true) ?  
                      (
                        <>
                          Amount cannot be higher than {maxPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}% of home value
                        </>
                      ) : (this.state.invalidHigh && fields.LoanAmountType === 'Jumbo' && showLtvMaxLimitInsteadOfLoanMaxLimit === false) ?
                        (
                          <>
                            Amount cannot be higher than ${numeral(maxAmount).format('0,0')}
                          </>
                        ) : this.state.invalidHigh ?
                          (
                            <>
                              Amount cannot be higher than ${numeral(maxAmount).format('0,0')} or {maxPercent.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]}%
                            </>
                          ) : (
                            <></>
                          )
                    }
                  </span>
                }
                open={this.state.invalidHigh || this.state.invalidLow}
              >
                <div>
                  <Input
                    hasexternallabel
                    id='quote--LoanAmount'
                    disabled={this.props.disabled}
                    value={
                      this.state.tempValue === ''
                        ? this.state.value === ''
                          ? ''
                          : parseInt(this.state.value)
                        : parseInt(this.state.tempValue)
                    }
                    mask="dollar"
                    invalid={this.state.invalidHigh || this.state.invalidLow || this.props.invalid}
                    invalidCheck={this.state.invalidHigh || this.state.invalidLow}
                    onChange={(e: any) => this.onChange(this.convertDollar(e.target.value))}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="col-4">
              <Tooltip
                html={<div />}
                error={true}
                distance={10}
                errorHtml={this.state.invalidLow ? Math.trunc(minPercent * 100) / 100 + '% Minimum' : ''}
                open={this.state.invalidLow && highestLTV !== 0}
              >
                <div>
                  <Input
                    id='quote--LoanAmountPercent'
                    hasexternallabel
                    value={fields.PurchasePrice !== '' ? this.state.percent : 0}
                    disabled={fields.PurchasePrice === ''}
                    mask="percent"
                    invalid={this.state.invalidHigh || this.state.invalidLow || this.props.invalid}
                    invalidCheck={this.state.invalidHigh || this.state.invalidLow}
                    onChange={(e: any) => this.onChangePercent(e.target.value)}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="form-row">
          <div className="col-8">
            <Input mask="dollar" disabled={true} label={this.props.label} value={''} id='quote--LoanAmount' />
          </div>
          <div className="col-4">
            <Input mask="percent" disabled={true} label={<>&nbsp;</>} value={''} id='quote--LoanAmountPercent' />
          </div>
        </div>
      );
    }
  }
}
