import React from 'react';

const { LiveChatLoaderProvider, HelpScout } = require('react-live-chat-loader')

const ChatWrapper: React.SFC<{children: React.ReactElement<any>}> = (props) => {

  return (
    <LiveChatLoaderProvider providerKey="3ff7898a-af5e-44f3-9eb9-c1d384cb2bea" provider="helpScout">
			<HelpScout color={'#ea701b'} horizontalPosition={'right'} icon={'message'} />

	    {props.children}

		</LiveChatLoaderProvider>

	)
}

export default ChatWrapper
