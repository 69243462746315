import React from 'react';
import { baseRealtyUrl, baseUrl, legacyUrl, getUrlWithUtmQueryString } from '@capcenter/shared';
import '../Realty.scss';
import { Tooltip } from '@capcenter/shared-legacy';

class SavingsSection extends React.Component {
  state = {
    purchasePrice: '$400,000',
    purchaseSavings: '$4,347',
    salePrice: '$400,000',
    saleSavings: '$12,300',
    totalSavings: '$16,647',
    savingsMessage: 'ZERO Listing Fees when you buy, sell, and finance with CapCenter.',
    savingsTooltipMessageHtml: (
      <div>
        <p>Zero Listing Fees when you sell, buy, and finance with CapCenter within one year. Restrictions apply.</p>
        <p>
          Industry's traditional listing fee is 3% and CapCenter's standard listing fee is 1.5% (half the traditional
          fee).
        </p>
        <p>
          When you sell, buy, and finance within a year, we refund CapCenter's 1.5% standard listing fee so you
          effectively pay Zero Listing Fees.
        </p>
      </div>
    ),
  };

  componentDidMount() {
    this.calculateSalesSavings();
  }

  constructor(props) {
    super(props);

    this.handlePurchasePriceChange = this.handlePurchasePriceChange.bind(this);
    this.handleSalePriceChange = this.handleSalePriceChange.bind(this);
  }
  
  wrapURLInQuoteParams(URL) {
    return URL +
      `?quoteWidget[price]=${Number(this.state.purchasePrice.replace(/[^0-9.-]+/g, ''))}`
    + `&quoteWidget[downDollar]=${Number(this.state.purchasePrice.replace(/[^0-9.-]+/g, '')) * 0.2}`
    + `&quoteWidget[loanDollar]=${Number(this.state.purchasePrice.replace(/[^0-9.-]+/g, '')) * 0.8}`
    + `&quoteWidget[FirstTimeHomeBuyer]=${this.state.salePrice === '$0' || this.state.salePrice === ''? 'true' : 'false'}`
    + "&quoteWidget[existingLoanBalance]=&quoteWidget[originalLoanAmount]=&quoteWidget[purchasedWithinLastYear]=false&quoteWidget[rate]=0&quoteWidget[LoanProgram]=Conventional&quoteWidget[LoanPurpose]=Purchase&quoteWidget[LoanTerm]=30-Year Term&quoteWidget[LoanType]=Fixed&quoteWidget[PropertyType]=Single Family Residence&quoteWidget[PropertyUse]=Primary Residence&quoteWidget[address]=Richmond, VA, USA&quoteWidget[PropertyLocation]=Richmond, VA, USA&quoteWidget[CountyId]=123&quoteWidget[CountyRealty]=Yes"
    + `&quoteWidget[NeedRealtyTeam]=${this.state.salePrice === '$0' || this.state.salePrice === ''? 'Yes' : 'No'}`
    + `&quoteWidget[savings]=${this.state.salePrice === '$0' || this.state.salePrice === ''? 'Realty' : 'Mortgage'}`
    + "&quoteWidget[BaseRate]=&quoteWidget[Points]=&quoteWidget[ProductCombinationId]=63&quoteWidget[StateId]=1&quoteWidget[region]=Central Va&quoteWidget[LoanAmountType]=Conforming&quoteWidget[refiOff]=&quoteWidget[isCashOutRefinanceDisabled]=&quoteWidget[HomeownersInsuranceEscrow]=0&quoteWidget[PropertyTax]=0";
  }
  
  normalize(value) {
    value = value.replace(/[^\d]/g, ''); // Remove any non-digit characters
    value = value.replace(/,/g, ''); // Remove commas signs
    value = value.replace(/\$/g, ''); // Remove leading dollar signs
    value = value.replace(/-/g, ''); // Remove negative signs
    let formattedValue = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');;
  
    return formattedValue ? '$' + formattedValue : '';
  }
  
  async getSaleSavings(sellingPrice, purchasePrice) {
    if (this.state.invalidSale) return 0;
    try {
      this.setState({loadingSale: true});
      const response = await fetch(
        baseRealtyUrl + 
        `/home-value-api/netProceeds?mortgage=0&salePrice=${sellingPrice}&realtyRate=${this.state.invalidPurchase ? 1.5 : 0}`,
      );
      this.setState({loadingSale: false});
      const responseBody = await response.json();
  
      if (responseBody.vals.savings.value) {
        return Math.max(0, responseBody.vals.savings.value);
      } else {
        throw new Error("No valid savings, use local calculation");
      }
    } catch (error) {
      console.log(error);
      this.setState({loadingSale: false});
      if (purchasePrice > 100000) {
        return sellingPrice * 0.03;
      } else {
        return sellingPrice * 0.015;
      }
    }
  }
  
  async getPurchaseSavings(purchasePrice, sellingPrice) {
    if (this.state.invalidPurchase) return 0;
    try {
      this.setState({loadingPurchase: true});
      const response = await fetch(
        (legacyUrl + '/QuoteService/api/Quote/TieredQuote?&CountyId=123')
        + `&DownPayment=${purchasePrice * .2}`
        + `&LoanAmount=${purchasePrice * .8}&LoanProgram=1&LoanPurpose=1&LoanTerm=1&LoanType=1`
        + `&NeedRealtyTeam=${sellingPrice > 0 ? 'false' : 'true'}&PropertyType=1&PropertyUse=1`
        + `&PropertyValue=${purchasePrice}`
        + `&PurchasePrice=${purchasePrice}&PreviousVALoan=false&RegularMilitary=false&HomeownersInsuranceEscrow=0&PropertyTax=0&ExistingLoanBalance=0&OriginalLoanAmount=0`
        + `&FirstTimeHomeBuyer=${sellingPrice > 0 ? 'false' : 'true'}&PurchasedWithinLastYear=false`
        );
        this.setState({loadingPurchase: false});
      const responseBody = await response.json();
  
      if (responseBody[0].Savings.TotalSavings) {
        return Math.max(0, responseBody[0].Savings.TotalSavings);
      } else {
        throw new Error("No valid savings, use local calculation");
      }
    } catch (error) {
      console.log(error);
      this.setState({loadingPurchase: false});
      return this.GetTitle(purchasePrice) + (purchasePrice > 150000 ? 2900 : 0)
    }
  }
  
  GetTitle(purchasePrice) {
    if (purchasePrice >= 2000000) {
      return 0.0029 * purchasePrice;
    }
  
    if (purchasePrice >= 1500000) {
      return 0.0036 * purchasePrice;
    }
  
    if (purchasePrice >= 1000000) {
      return 0.0035 * purchasePrice;
    }
  
    if (purchasePrice >= 700000) {
      return 0.0036 * purchasePrice;
    }
  
    return 0.00375 * purchasePrice;
  }
  
  ConvertToCurrency(dollars) {
    var formatting_options = {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    };
    var dollarString = new Intl.NumberFormat('en-US', formatting_options);
  
    return dollarString.format(dollars);
  }

  normalizePurchasePrice(e) {
    this.setState({ purchasePrice: this.normalize(e.target.value) });
  }

  normalizeSalePrice(e) {
    this.setState({ salePrice: this.normalize(e.target.value) });
  }

  async calculateSalesSavings() {
    //Retrieve inputs and convert to numeric
    const sellingPriceString = this.state.salePrice;
    const sellingPrice = Number(sellingPriceString.replace(/[^0-9.-]+/g, ''));
    const purchasePriceString = this.state.purchasePrice;
    const purchasePrice = Number(purchasePriceString.replace(/[^0-9.-]+/g, ''));

    //Calculate savings
    this.setState({ invalidSale: (sellingPrice <= 0), invalidPurchase: ((purchasePrice * .80) < 100000)})
    const sales_savings = await this.getSaleSavings(sellingPrice, purchasePrice);
    const buy_savings = await this.getPurchaseSavings(purchasePrice, sellingPrice);
    const total_savings = sales_savings + buy_savings;

    if (sellingPrice > 0 && purchasePrice > 0) {
      this.setState({
        savingsMessage: 'ZERO Listing Fees when you buy, sell, and finance with CapCenter.',
        savingsTooltipMessageHtml: (
          <div>
            <p>Zero Listing Fees when you sell, buy, and finance with CapCenter within one year. Restrictions apply.</p>
            <p>
              Industry's traditional listing fee is 3% and CapCenter's standard listing fee is 1.5% (half the
              traditional fee).
            </p>
            <p>
              When you sell, buy, and finance within a year, we refund CapCenter's 1.5% standard listing fee so you
              effectively pay Zero Listing Fees.
            </p>
          </div>
        ),
      });
    } else if (sellingPrice > 0) {
      this.setState({
        savingsMessage: '50% off Listing Fee when you sell with CapCenter.',
        savingsTooltipMessageHtml: (
          <div>
            <p>
              Traditional real estate listing fee is 3% of the sale price (i.e. $12000 for a $400k home). CapCenter's
              standard listing fee is 1.5%, which is half the traditional listing fee.
            </p>
            <p>Bundle purchase and financing within a year for Zero Listing Fees.</p>
          </div>
        ),
      });
    } else if (purchasePrice > 0) {
      this.setState({
        savingsMessage: 'ZERO Closing Costs and 1/8 rate discount when you buy and finance with CapCenter.',
        savingsTooltipMessageHtml: (
          <div>
            <p>
              Traditional mortgage lenders charge thousands of dollars in closing costs. At CapCenter, we waive
              origination fees, appraisal fees, credit report fees, title services fees, title insurance fees,
              settlement agent fees, and more!
              <br />
              <br />
              Get an additional 1/8 off your interest rate when you buy & finance with CapCenter. 1/8 rate reduction
              offer cannot be combined with ZERO Listing Fees.
            </p>
          </div>
        ),
      });
    } else {
      this.setState({
        savingsMessage: 'Enter sale price values to see how much you can save.',
        savingsTooltipMessageHtml: (
          <div>
            <p>Enter $0 in sale price to exclude service from savings calculation</p>
          </div>
        ),
      });
    }

    this.setState({
      totalSavings: this.ConvertToCurrency(total_savings),
      purchaseSavings: this.ConvertToCurrency(buy_savings),
      saleSavings: this.ConvertToCurrency(sales_savings),
    });
  }

  handlePurchasePriceChange(event) {
    this.setState({ purchasePrice: this.normalize(event.target.value) });
  }
  handleSalePriceChange(event) {
    this.setState({ salePrice: this.normalize(event.target.value) });
  }

  render() {
    return (
      <section className="section-savings rtb-flex">
        <div className="rtb-text shadow p-4 calculator" style={{ borderRadius: '25px', border: 'solid 2px #FFF5ED' }}>
          <h2 className={'save mb-3'}>
            <i className="fas fa-sack-dollar"></i>
            Save up to <span className={'savings'}>{this.state.totalSavings}</span>!
          </h2>
          <p>
            {this.state.savingsMessage}{' '}
            <Tooltip arrow html={this.state.savingsTooltipMessageHtml}>
              <i className="fas fa-info-circle"></i>
            </Tooltip>
          </p>

          <div className="mb-2 container">
            <div className={'row mb-2'}>
              <div className={'col-md-2 col-2 text-left'}></div>
              <div className={'col-md-5 col-5 text-left'}></div>
              <div className={'col-md-5 col-5 text-left'}></div>
            </div>
          </div>

          <div className="mb-2 container">
            <div className={'row mb-2 nowrap'}>
              <div className={'col-md-2 col-2 text-left p-0 savings-header'}>Service</div>
              <div className={'col-md-5 col-5 text-left savings-header'}>
                Sale price{' '}
                <i
                  className="fas fa-calculator mr-2"
                  title={'Enter your expected sale price(s) to see how much you can save'}
                ></i>{' '}
              </div>
              <div className={'col-md-5 col-5 text-left savings-header'}>Savings</div>
            </div>
            <div className={'row mb-2'}>
              <a
                className={'col-md-2 col-2 savings-one'}
                href={getUrlWithUtmQueryString(
                  `${baseRealtyUrl}/home-sale-estimate/valuation?customValue=${Number(
                    this.state.salePrice.replace(/[^0-9.-]+/g, ''),
                  )}&realtyRateType=${this.state.invalidPurchase ? 'List' : 'ListBuyFinance'}`
                )
              }
              >
                SELL
              </a>
              <div className={'col-md-5 col-5 savings-two'}>
                <input
                  type="text"
                  onChange={this.handleSalePriceChange}
                  onInput={() => this.normalizeSalePrice}
                  onBlur={() => this.calculateSalesSavings()}
                  className="form-control"
                  id="sell-home-price"
                  placeholder="Home sale price"
                  value={this.state.salePrice}
                />
              </div>
              <div
                className={'col-md-5 col-5 savings-three pl-0 pr-0'}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span className="ml-0 mr-1 p-0">
                  {this.state.saleSavings}
                  {this.state.loadingSale && <span class="loader" />}
                </span>
                <a
                  className="mr-2 p-0"
                  href={getUrlWithUtmQueryString(
                    `${baseRealtyUrl}/home-sale-estimate/valuation?customValue=${Number(
                      this.state.salePrice.replace(/[^0-9.-]+/g, ''),
                    )}&realtyRateType=${this.state.invalidPurchase ? 'List' : 'ListBuyFinance'}`
                  )
                }
                >
                  <button className="btn-secondary small m-0" title="Free anonymous sale quote before you apply">
                    Details
                  </button>
                </a>
              </div>
            </div>
            <div className={'row mb-2'}>
              <a
                className={'col-md-2 col-2 savings-one'}
                href={getUrlWithUtmQueryString(this.wrapURLInQuoteParams(`${baseUrl}/mortgage-calculator/purchase`))}
              >
                BUY
              </a>
              <div className={'col-md-5 col-5 savings-two'}>
                  <div>
                    <input
                      type="text"
                      onChange={this.handlePurchasePriceChange}
                      onInput={() => this.normalizePurchasePrice}
                      onBlur={() => this.calculateSalesSavings()}
                      className="form-control"
                      id="buy-home-price"
                      placeholder="Home purchase price"
                      value={this.state.purchasePrice}
                    />
                  </div>
              </div>
              <div
                className={'col-md-5 col-5 savings-three pl-0 pr-0'}
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <span className="ml-0 mr-1 p-0">
                  {this.state.purchaseSavings}
                  {this.state.loadingPurchase && <span class="loader" />}
                </span>
                <a
                  className="mr-2 p-0"
                  href={getUrlWithUtmQueryString(this.wrapURLInQuoteParams(`${baseUrl}/mortgage-calculator/purchase`))}
                >
                  <button className="btn-secondary small m-0" title="Free anonymous purchase quote before you apply">
                    Details
                  </button>
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className="container">
            <div className={'row mb-2'}>
              <div className={'col-md-7 col-7 text-right savings-two-total pt-2'}>Your estimated total savings</div>
              <div className={'col-md-5 col-5 text-left savings-three total pl-0'}>{this.state.totalSavings}</div>
            </div>
            <div className={'row mb-2'}>
              <div className={'col-md-12 col-12'}>
                <button
                  className="btn-secondary mr-2"
                  onClick={() => {
                    this.setState({
                      purchasePrice: '$400,000',
                      purchaseSavings: '$4,347',
                      salePrice: '$400,000',
                      saleSavings: '$12,300',
                      totalSavings: '$16,647',
                      savingsMessage: 'ZERO Listing Fees when you buy, sell, and finance with CapCenter.',
                    });
                  }}
                >
                  Reset
                </button>
                <button
                  className="btn-secondary"
                  onClick={() => {
                    this.setState({
                      purchasePrice: '$0',
                      salePrice: '$0',
                      purchaseSavings: '$0',
                      saleSavings: '$0',
                      totalSavings: '$0',
                      savingsMessage: 'Enter sale price values to see how much you can save.',
                    });
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="rtb-text">
          <h2>
            <i className="fas fa-sign"></i> Get started with a local REALTOR®
          </h2>
          <p>We are committed to providing the quality you expect and the savings you deserve.</p>

          <div className="profile-container">
            <div className="profile">
              <img
                className="profile-image"
                src={require('../../assets/best-realtor-stephany.jpg')}
                alt="Top-rated CapCenter Realty Agency"
              />
              <p className="profile-name">Stephany Lopez</p>
              <p className="profile-title">Team Lead</p>
            </div>
            <div className="profile">
              <img
                className="profile-image"
                src={require('../../assets/best-broker-chris.jpg')}
                alt="Top-rated CapCenter Realty Agency"
              />
              <p className="profile-name">Chris Piacentini</p>
              <p className="profile-title">Principal Broker</p>
            </div>
            <div className="profile">
              <img
                className="profile-image"
                src={require('../../assets/best-realtor-hope.jpg')}
                alt="Top-rated CapCenter Realty Agency"
              />
              <p className="profile-name">Hope Foster</p>
              <p className="profile-title">Team Lead</p>
            </div>
            <div className="profile">
              <img
                className="profile-image"
                src={require('../../assets/best-broker-brian.png')}
                alt="Top-rated CapCenter Realty Agency"
              />
              <p className="profile-name">Brian McIntyre</p>
              <p className="profile-title">Managing Broker</p>
            </div>
          </div>

          <div  className="mb-2">
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/forms/request-realty`)} className="mr-4">
              <button className="btn-primary" title="Schedule a consultation with our realty team">
                Request REALTOR®
              </button>
            </a>
            <a href={getUrlWithUtmQueryString(`${baseRealtyUrl}/find-best-local-realtor`)}>
              <button
                className="btn-secondary"
                title="Meet more CapCenter realty team members"
              >
                <p className="d-sm-inline d-none">Meet more team members</p>
                <p className="d-inline d-sm-none">Meet team</p>
              </button>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default SavingsSection;
