import React from 'react';
import AppList from './AppList.json';
import Colors from './_colors.scss';
import Weights from './_weights.scss';
import { baseUrl } from '../Config';

export const QuoteDisclosure = (props: { showMore: boolean; }) => (
  <small style={{ color: Colors.gray600, display: 'inline-block', lineHeight: 1.2 }}>
    The items marked as "ZERO" above are either waived or paid for by CapCenter when you close your loan. 
    On your Loan Estimate or Closing Disclosure, these items may appear as costs, but they will be offset 
    by a lender credit. The industry costs shown are estimated averages based on cost comparisons with other 
    lenders. As a result, other lenders’ actual fees may be lower or higher than illustrated. 
    <br/>
    <br/>
    Rates are subject to change without notice.
    <br/>
    {props.showMore === true && <>
    <br/>
    The estimates shown above are based on the selections you’ve made and the following assumptions:
    <br/>
    &emsp;•	Borrower(s) credit score(s) of 780 or higher.
    <br/>
    &emsp;•	You will escrow property taxes, homeowner's insurance, and, if needed, flood insurance.
    <br/>
    &emsp;•	For Veterans Affairs (“VA”) loans:
    <br/>
    &emsp;&emsp;o	Regular refinance: Loan will be an Interest Rate Reduction Refinance Loan (IRRRL)
    <br/> 
    &emsp;&emsp;o	Cash-out refinance: You have previously used a VA loan.
    <br/>
    &emsp;&emsp;o	All transactions: The VA Funding Fee will be financed into the loan amount and not paid at closing
    <br/>
    &emsp;•	For Federal Housing Administration (“FHA”) loans:
    <br/>
    &emsp;&emsp;o	The Upfront Mortgage Insurance Premium will be financed into the loan amount and not paid at closing
    <br/>
    <br/>
    The estimated “cash to close” shown above doesn't include the following prepaid items:
    <br/>
    &emsp;•	Prepaid interest: Depending on your closing date, you may be required to pay interest on your loan to cover the gap between closing and your first payment due date.
    <br/> 
    &emsp;•	Prepaid homeowner’s insurance and/or property taxes: If your homeowner’s insurance and/or property taxes are due within 60 days of your closing date, you may be required to pay these amounts in advance at closing. 
    <br/>
    &emsp;•	Funding your escrow amount: If your loan has an escrow account, you may be required to fund it with a portion of your homeowner’s insurance and/or property taxes. This ensures that there is enough in the account to make the payments when they are due. The amount you may be required to fund upfront depends on your closing date and the due dates of your homeowner’s insurance and property taxes.   
    <br/>
    <br/>
    If you apply for a loan, a CapCenter Loan Consultant will walk through loan term options personalized to you. 
    Additional fees not shown here may apply in some circumstances. You can find more information in our Assumptions & Disclosures (link).
    <br/>
    <br/>
    Also, please note that when you proceed with a CapCenter loan, you will be charged an upfront Good Faith Deposit, 
    which will be refunded in full if you close your loan. However, if you don't close your loan, CapCenter may keep 
    some or all of your Good Faith Deposit to offset any fees paid by CapCenter on your behalf (marked “ZERO”).
    </>
    }
  </small>
);

export const SellDisclosure = () => (
  <span style={{ color: 'inherit', display: 'inline-block', lineHeight: 1.2 }}>
    Standard listing fees are 1.5%. List your home with ZERO listings fees when you buy, sell, and finance within 1 year with CapCenter. 
    List your home with 1% listing fees when you buy and sell within 1 year with CapCenter. All values within this tool are estimates 
    and are meant for informational purposes only. Actual amounts may vary. See the&nbsp;
    <a style={{ color: 'inherit', textDecoration: 'underline' }} href={AppList.disclosures.url + '#!sell'}>
      Terms &amp; Conditions
    </a>{' '}
    for details and restrictions.
  </span>
);

export const SellPrompt = () => (
  <div style={{ fontStyle: 'normal' }}>
    <span style={{ fontWeight: Weights.bolder }}>What&apos;s the catch?</span>
    <br />
    There isn&apos;t one! If you just want to list your home through us, our standard listing fees are only 1.5% -
    that&apos;s 1/2 the industry average.
    <br />
    <br />
    Enjoy an even lower listing fees of 0% if you list and buy within 1 year through CapCenter. See the{' '}
    <a href={baseUrl + AppList.disclosures.url + '#!sell'}>Terms &amp; Conditions</a> for details and restrictions.
  </div>
);
