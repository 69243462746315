import React from 'react';
import { getTieredQuote } from '@capcenter/shared-legacy';

import MapQuote from './MapQuote';

export default class Quote extends React.Component<{
  ready: boolean;
  readyFields: any;
  fields: any;
  children: (props: any) => React.ReactElement<any>;
}> {
  readonly state = {
    quotes: [],
    mapped: {},
  };

  componentDidUpdate() {
    if (this.props.ready) {
      this.getQuote();
    }
  }

  getQuote = () => {
    const newMapped = MapQuote(this.props.readyFields);

    if (JSON.stringify(newMapped) !== JSON.stringify(this.state.mapped)) {
      this.setState({ mapped: newMapped });
      getTieredQuote(newMapped).then((quotes: any) => {
        if (JSON.stringify(quotes) !== JSON.stringify(this.state.quotes)) this.setState({ quotes });
      });
    }
  };

  render() {
    return <>{this.props.children({ quotes: this.state.quotes })}</>;
  }
}
