import { quoteUrl } from '@capcenter/shared';

export default async function getQuoteRates(queryParameters : string): Promise<any | string> {
  const url: string = quoteUrl + 'Quote/LLPARates';
  try {
    return await (await fetch(url + "?" + queryParameters)).json();
  } catch (err) {
    console.log(err);

    return 'Unexpected Quote Rates Database Error. Please try again later.';
  }
}