import { quoteUrl } from '@capcenter/shared';
import QuoteRates from "./interfaces/QuoteRates";

type QuoteRatesParameters = {
  CountyID?: number,
  LoanPurposeID: number,
  LoanProgramID: number,
  LoanTermID: number,
  LoanTypeID: number,
  PropertyUseID: number,
  LoanAmountValue?: number
}

let queryParametersDefaults : QuoteRatesParameters = {
  LoanPurposeID: 0,
  LoanProgramID: 0,
  LoanTermID: 0,
  LoanTypeID: 0,
  PropertyUseID: 0
}

export default async function getQuoteRates(queryParameters : Partial<QuoteRatesParameters>): Promise<QuoteRates | string> {
  const url: string = quoteUrl + 'Quote/Rates';

  try {
    const queryParametersAfterDefaults : {[index: string]: any} =
        Object.assign(queryParametersDefaults, queryParameters);

    const queryString : string =
        Object.keys(queryParametersAfterDefaults).map(key => key + "=" + queryParametersAfterDefaults[key]).join("&");

    let response = await fetch(url + "?" + queryString);
    let parsedResponse = await response.json();
    let quoteRates: QuoteRates = parsedResponse;

    return quoteRates;
  } catch (err) {
    console.log(err);

    return 'Unexpected Quote Rates Database Error. Please try again later.';
  }
}
