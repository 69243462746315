import ProductKeys from '../../ProductKeys.json';
import queryString from 'qs';

const convertDollar = (value: string) => Number(value.replace(/[^0-9-]+/g, ''));

const mapQuote = (fields: any) => {
  const request = {
    CountyId: fields.CountyId,
    DownPayment: fields.DownPayment,
    LoanAmount: fields.LoanAmount,
    LoanProgram: ProductKeys.LoanProgram.indexOf(fields.LoanProgram),
    LoanPurpose: ProductKeys.LoanPurpose.indexOf(fields.LoanPurpose),
    LoanTerm: ProductKeys.LoanTerm.indexOf(fields.LoanTerm),
    LoanType: ProductKeys.LoanType.indexOf(fields.LoanType),
    NeedRealtyTeam: fields.NeedRealtyTeam === '' ? false : fields.NeedRealtyTeam === 'Yes' ? true : false,
    PropertyType: ProductKeys.PropertyType.indexOf(fields.PropertyType),
    PropertyUse: ProductKeys.PropertyUse.indexOf(fields.PropertyUse),
    PropertyValue: fields.PurchasePrice,
    PurchasePrice: fields.PurchasePrice,
    PreviousVALoan: fields.PreviousVALoan === '' ? false : fields.PreviousVALoan,
    RegularMilitary: fields.RegularMilitary === '' ? false : fields.RegularMilitary,
    HomeownersInsuranceEscrow: fields.HomeownersInsuranceEscrow ? convertDollar(fields.HomeownersInsuranceEscrow) : 0,
    PropertyTax: fields.PropertyTax ? convertDollar(fields.PropertyTax) : 0,
    ExistingLoanBalance:
      fields.ExistingLoanBalance && fields.LoanPurpose === 'Cash Out Refinance'
        ? convertDollar(fields.ExistingLoanBalance)
        : 0,
    OriginalLoanAmount:
      fields.OriginalLoanAmount && fields.LoanPurpose !== 'Purchase' ? convertDollar(fields.OriginalLoanAmount) : 0,
    FirstTimeHomeBuyer: fields.FirstTimeHomeBuyer,
    PurchasedWithinLastYear: !!fields.PurchasedWithinLastYear,
  };

  //console.log(request)

  return queryString.stringify(request);
};

export default mapQuote;
