import React, { Component } from 'react';
import {mapQuoteWidgetFromFields, QuoteLoader} from '@capcenter/quote'
import RefiSavingsTooltipFields from "./RefiSavingsTooltipFields";
import RefiSavingsComponentFields from "./RefiSavingsComponentFields";
import numeral from "numeral";
import {Colors, getUrlWithUtmQueryString} from "@capcenter/shared";
import queryString from "qs";

class RefiSavings extends Component {
	constructor(props) {
		super(props);

		const initialValue = 320000;
		const refiSavingsValue = props.refiSavings?.value ?? initialValue;
		const purchasePrice = refiSavingsValue / 0.8;
		const loanAmount = refiSavingsValue;
		const downPayment = purchasePrice - loanAmount;

		this.state = {
			sliderValue: refiSavingsValue,
			initialPurchasePrice: purchasePrice,
			initialLoanAmount: loanAmount,
			initialDownPayment: downPayment,
		}
	}

	setSliderValue = (value) => {
		this.setState({ sliderValue: value });
	};

	render(){

    return (
			<>
					<QuoteLoader defaultState quoteWidget={{
						PurchasePrice: this.state.initialPurchasePrice,
						LoanAmount: this.state.initialLoanAmount,
						DownPayment: this.state.initialDownPayment,
						FirstTimeHomeBuyer: false,
					}}>
						{(props) =>
							this.props.tooltip ?
								<RefiSavingsTooltipFields
									LoanPurpose="Refinance"
									link={getUrlWithUtmQueryString(this.props.thisPage.path)}
									sliderValue={this.state.sliderValue}
									setSliderValue={this.setSliderValue}
									tabClick={this.props.tabClick}
									tooltipOpen={this.props.tooltipOpen}
									setOpen={this.props.setOpen}
									text="Zero Closing Costs"
									savings={props.quotes.length !==0 ? "$" + numeral(props.quotes[0].Savings.TotalSavings).format("0,0") : "$_,___"}
									label="Loan of"
									value={props.fields.LoanAmount ? "$" + numeral(props.fields.LoanAmount).format("0.[0]a"): "$___k"}
									first
									{...props}
									{...this.props} /> :

								<RefiSavingsComponentFields
									LoanPurpose="Refinance"
									text="Refinance "
									sliderValue={this.state.sliderValue}
									setSliderValue={this.setSliderValue}
									savings={props.quotes.length !== 0 && props.quotes[0].Savings.TotalSavings}
									offset={0}
									table={[
										{
											text: "CapCenter Closing Costs",
											value: props.quotes.length !== 0 && props.quotes[0].CashToClose.Total.CapcenterFee,
											color: Colors.primary
										},{
											text: "Industry Avg. Closing Costs",
											value: props.quotes.length !== 0 && props.quotes[0].CashToClose.Total.CompetitorFee,
										},{
											text: "Savings with Zero Closing Costs",
											value: props.quotes.length !== 0 && props.quotes[0].Savings.TotalSavings,
											color: Colors.green500,
											total: true,
										}

									]}
									desc="Example: 30 Year, Primary Residence"
									link={getUrlWithUtmQueryString("/mortgage-calculator/refinance?" + queryString.stringify({
										quoteWidget: mapQuoteWidgetFromFields(props.fields)
									})) + "#savings"}
									{...props} {...this.props} />}
							</QuoteLoader>
			</>
		)
	}
}

export default RefiSavings;