import queryString from 'qs';

const decode = (object: any) => {

  let decoded: any = {}

  Object.keys(object).map(function (key) {

    if (!isNaN(object[key])) {
      decoded[key] = parseInt(object[key], 0);
    }

    if (isNaN(decoded[key]) || decoded[key] === "") {
      if (object[key] === "true") {
        decoded[key] = true;
      } else if (object[key] === "false") {
        decoded[key] = false;
      } else {
        decoded[key] = object[key]
      }
    }

    return decoded[key];

  });

  return decoded;
}

// /?quoteWidget%5BLoanPurpose%5D=Refinance&quoteWidget%5Bregion%5D=Central%20Va&quoteWidget%5BdownDollar%5D=91500&quoteWidget%5BhideSubmit%5D=true&quoteWidget%5Blite%5D=false&quoteWidget%5BloanDollar%5D=208500&quoteWidget%5BurlQuery%5D=true#savings

const check = (value: any) => {
  if (value !== undefined) {
    return value
  } else {
    return ""
  }
}

const checkBool = (value: any) => {
  if (value !== undefined) {
    return value ? "Yes" : "No"
  } else {
    return ""
  }
}
const checkPhoneNumber = (value: any) => {
  if (value) {
    const stringValue = value.toString()

    if (!isNaN(parseInt(value)) && stringValue.length === 10) {
      const phonenumberstring = stringValue.slice(0, 3) + "-" + stringValue.slice(3, 6) + "-" + stringValue.slice(6);

      return phonenumberstring;
    }
  }
  return ""
}

const initHomeValue = 400000;
const initDownDollar = (initHomeValue * 20) / 100;
const initLoanDollar = initHomeValue - ((initHomeValue * 20) / 100);

const quoteWidgetDefault = {
  LoanAmountType: "Conforming",
  LoanTerm: "30-Year Term",
  LoanProgram: "Conventional",
  LoanPurpose: "Purchase",
  PropertyUse: "Primary Residence",
  PropertyType: "Single Family Residence",
  LoanType: "Fixed",
  PreviousVALoan: false,
  RegularMilitary: false,
  Reserves: false,
  HomeownersInsuranceEscrow: 0,
  PropertyTax: 0,
  PreApproval: false,
  address: "Richmond, VA",
  countyId: 123,
  stateId: 1,
  region: "Central VA",
  debug: false,
  embed: false,
  applySnippet: false,
  applyBtn: false,
  admin: false,
  downDollar: initDownDollar,
  downLock: true,
  downLockTip: false,
  exceptions: {},
  hideSubmit: false,
  jumbo: false,
  lite: true,
  limits: {LowBalanceLowerLimit: 75000, JumboLimit: 2000001, LowerLoanLimit: 100000, ConformingLimit: 424100},
  loading: true,
  loadingWarning: false,
  loanDollar: initLoanDollar,
  countyJumbo: 535900,
  minDown: 5,
  price: initHomeValue,
  realtyActive: true,
  rate: 0,
  savings: "Realty",
  quoteThis: true,
  error: false,
  query: [],
  quoteCount: 0,
  PropertyTaxPercent: 0,
  HomeownersInsuranceEscrowPercent: 0,
  hideRealty: false,
  hideLoanPurpose: false,
  rateWidget: false,
  urlQuery: false,
  hideSavings: false,
  FirstTimeHomeBuyer: ""
}

const mapQuoteWidget = () => {

  // Get Preferences from URL Query
  if (window.location.search !== "") {

    const query = window.location.search.substring(1);
    const parsed = queryString.parse(query);
    if (parsed.quoteWidget !== undefined) {
      let decoded = decode(parsed.quoteWidget);

      let queryObject = JSON.parse(JSON.stringify(decoded));
      delete queryObject.query;
      decoded.query = queryObject;

      let d = decoded

      if (parsed.fromQuote === "true") {
        d = Object.assign(quoteWidgetDefault, decoded);
      }

      const NeedRealtyTeam = d.NeedRealtyTeam ? d.NeedRealtyTeam : (
        d.savings === undefined || d.savings === "" ? "" : (d.savings === "Realty" ? "Yes" : "No"));

      const mappedValues = {
        LoanPurpose: check(d.LoanPurpose),
        PropertyLocation: check(d.address),
        CountyId: check(d.countyId).toString(),
        StateId: check(d.stateId).toString(),
        CountyRealty: checkBool(d.realtyActive),
        region: check(d.region),
        PurchasePrice: d.price === undefined || isNaN(d.price) ?
          (d.downDollar !== undefined && !isNaN(d.downDollar) && d.loanDollar !== undefined && !isNaN(d.loanDollar) ?
            (d.downDollar + d.loanDollar).toString() : "") : d.price.toString(),
        DownPayment: check(d.downDollar).toString(),
        LoanAmount: check(d.loanDollar).toString(),
        LoanAmountType: check(d.LoanAmountType),
        LoanTerm: check(d.LoanTerm),
        LoanType: check(d.LoanType),
        LoanProgram: check(d.LoanProgram),
        PreviousVALoan: checkBool(d.PreviousVALoan),
        RegularMilitary: checkBool(d.RegularMilitary),
        Reserves: checkBool(d.Reserve),
        PropertyUse: check(d.PropertyUse),
        PropertyType: check(d.PropertyType),
        HomeownersInsuranceEscrow: check(d.HomeownersInsuranceEscrow).toString(),
        PropertyTax: check(d.PropertyTax).toString(),
        NeedRealtyTeam: NeedRealtyTeam,
        rateKey: check(d.rate).toString(),
        BaseRate: "",
        Points: "",
        listLead: check(d.listLead),
        buyLead: check(d.buyLead),
        listCrossSell: check(d.listCrossSell),
        buyCrossSell: check(d.buyCrossSell),
        ProductCombinationId: "",
        refiOff: "",
        FirstTimeHomeBuyer: check(d.FirstTimeHomeBuyer).toString(),
        ExistingLoanBalance: check(d.existingLoanBalance).toString(),
        OriginalLoanAmount: check(d.originalLoanAmount).toString(),
        PurchasedWithinLastYear: d.purchasedWithinLastYear
      }

      return mappedValues
    } else {
      return {LoanPurpose: ""}
    }
  } else {
    return {LoanPurpose: ""}
  }

  //return fields;

}

export default mapQuoteWidget;
