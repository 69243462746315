import { quoteUrl } from '@capcenter/shared';

const getTieredQuote = query => {
  const t0 = performance.now();

  return fetch(quoteUrl + 'Quote/TieredQuote?' + query, {
    method: 'GET',
    headers: {
      Accept: 'text/plain',
    },
  })
    .then(response => {
      let responseText = response.text();

      return responseText;
    })
    .then(responseText => {
      const quote = JSON.parse(responseText);

      if (quote.error !== undefined) {
        console.error(quote.error);
        return '';
      } else {
        const sortedQuote = quote.sort(function (a, b) {
          return a.Rate.Points - b.Rate.Points;
        });

        return sortedQuote;
      }
    })
    .catch(error => {
      console.error('Get Error', error);

      return 'Unexpected Quote Database Error. Please try again later.';
    });
};

export default getTieredQuote;
