import React from 'react';
import FieldsInt from '../Fields.interface';

export default class StabilityCheck extends React.Component<{
  fields: FieldsInt;
  children: (props: any) => React.ReactElement<any>;
}> {
  readonly state = {
    validQuote: false, // Will the current state produce a valid quote?
    stable: false, // Has it been a few milliseconds since the state has been updated?
    updateCount: 0, // How many times the state has updated?
    readyFields: {}
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    const thisUpdateCountString = this.state.updateCount.toString();
    let thisIncrementedUpdateCount = this.state.updateCount + 1;

    if (prevProps !== this.props) {
      this.setState({stable: false, validQuote: false, updateCount: thisIncrementedUpdateCount});
    }

    setTimeout(() => {
      if (thisUpdateCountString === this.state.updateCount.toString() && this.state.stable === false) {
        this.validateQuote();
      }
    }, 200);
  }

  validateQuote = () => {
    if (
      this.props.fields.LoanProgram !== '' &&
      this.props.fields.LoanPurpose !== '' &&
      this.props.fields.LoanTerm !== '' &&
      this.props.fields.LoanType !== '' &&
      this.props.fields.PropertyType !== '' &&
      this.props.fields.PropertyUse !== '' &&
      (this.props.fields.LoanPurpose === 'Purchase' ? this.props.fields.DownPayment !== '' : true) &&
      this.props.fields.LoanAmount !== '' &&
      this.props.fields.PurchasePrice !== '' &&
      this.props.fields.PropertyLocation !== '' &&
      this.props.fields.CountyId !== '' &&
      this.props.fields.CountyRealty !== ''
    ) {
      this.setState({ validQuote: true, stable: true, bigLoad: false, readyFields: this.props.fields });
    } else {
      this.setState({ validQuote: false, stable: true, bigLoad: false });
    }
  };

  render() {
    return <>{this.props.children({
      ready: this.state.stable && this.state.validQuote,
      readyFields: this.state.readyFields })}</>;
  }
}
