import { baseRealEstateUrl, getUrlWithUtmQueryString } from '@capcenter/shared';
import React from 'react';
import numeral from 'numeral';
import '../Realty.scss';

let htmlListings = [];

async function getListings(pageType) {
  const numberOfListings = 5;

  let response = await window.fetch(`/elastic/listings/_search`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body:
      pageType === 'sell'
        ? JSON.stringify({
            size: numberOfListings,
            sort: [{ isCapcenterListing: { order: 'desc' } }, { CloseDate: { order: 'desc' } }],
            query: {
              function_score: {
                query: {
                  bool: {
                    filter: [
                      { exists: { field: 'ListPrice' } },
                      { exists: { field: 'location' } },
                      { exists: { field: 'CloseDate' } },
                      {
                        bool: {
                          should: [
                            { term: { 'PropertyType.keyword': 'Residential' } },
                            { term: { 'PropertyType.keyword': 'Residential Income' } },
                            { term: { 'PropertyType.keyword': 'Land' } },
                          ],
                        },
                      },
                      { term: { 'StandardStatus.keyword': 'Closed' } },
                      { bool: { must: [{ match: { isCapcenterListing: true } }] } },
                    ],
                    should: [],
                    must: [],
                    must_not: [],
                  },
                },
              },
            },
            _source: [
              'id',
              'normalized_address',
              'ListPrice',
              'location',
              'ccCounty',
              'CloseDate',
              'ListingId',
              'City',
              'CountyOrParish',
              'PostalCode',
              'StandardStatus',
              'StreetDirPrefix',
              'StreetSuffix',
              'StreetDirSuffix',
              'StreetName',
              'StreetNumber',
              'StateOrProvince',
              'OriginatingSystemName',
              'UnitNumber',
              'UnparsedAddress',
              'ListOfficeMlsId',
              'isCapcenterListing',
              'OnMarketDate',
              'Photos',
              'BedroomsTotal',
              'RawMlsBathroomsTotal',
              'LivingArea',
              'LotSizeAcres',
            ],
          })
        : JSON.stringify({
            size: numberOfListings,
            sort: [{ isCapcenterListing: { order: 'desc' } }, { OnMarketDate: { order: 'desc' } }],
            query: {
              function_score: {
                query: {
                  bool: {
                    filter: [
                      { exists: { field: 'ListPrice' } },
                      { exists: { field: 'location' } },
                      {
                        bool: {
                          should: [
                            { term: { 'PropertyType.keyword': 'Residential' } },
                            { term: { 'PropertyType.keyword': 'Residential Income' } },
                            { term: { 'PropertyType.keyword': 'Land' } },
                          ],
                        },
                      },
                      { term: { 'StandardStatus.keyword': 'Active' } },
                      { bool: { must: [{ match: { isCapcenterListing: true } }] } },
                    ],
                    should: [],
                    must: [],
                    must_not: [],
                  },
                },
              },
            },
            _source: [
              'id',
              'normalized_address',
              'ListPrice',
              'location',
              'ccCounty',
              'CloseDate',
              'ListingId',
              'City',
              'CountyOrParish',
              'PostalCode',
              'StandardStatus',
              'StreetDirPrefix',
              'StreetSuffix',
              'StreetDirSuffix',
              'StreetName',
              'StreetNumber',
              'StateOrProvince',
              'OriginatingSystemName',
              'UnitNumber',
              'UnparsedAddress',
              'ListOfficeMlsId',
              'isCapcenterListing',
              'OnMarketDate',
              'Photos',
              'BedroomsTotal',
              'RawMlsBathroomsTotal',
              'LivingArea',
              'LotSizeAcres',
            ],
          }),
  });

  return await response.json();
}

function buildPropertyAddress(listingSource) {
  const addressLineOne = listingSource?.UnparsedAddress?.replace(/\s/g, '-').replace(/#/g, 'Unit-');
  const city = listingSource?.City?.replace(/\s/g, '-');
  const stateOrProvince = listingSource?.StateOrProvince?.replace(/\s/g, '-');
  const postalCode = listingSource?.PostalCode;

  const propertyAddress = `${addressLineOne}-${city}-${stateOrProvince}-${postalCode}`;

  return propertyAddress;
}

function buildListingURL(listingSource, listingSourceId) {
  const propertyAddress = buildPropertyAddress(listingSource);

  return getUrlWithUtmQueryString(`${baseRealEstateUrl}/homes/property-details/${encodeURIComponent(propertyAddress)}/${listingSourceId}`);
}

function titleCheck(str) {
  return str ? toTitleCase(str) : '';
}

function toTitleCase(str) {
  if (!str) {
    return '';
  }

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function currencyFormat(num) {
  return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function upperCheck(str) {
  return str ? str.toUpperCase() : '';
}

async function renderListings(pageType) {
  const listingResponse = await getListings(pageType);
  const listings = listingResponse.hits.hits;

  for (let i = 0; i < listings.length; i++) {
    let property = listings[i]._source;
    if (property.Photos && property.Photos.length > 0) {
      let listItem = (
        <a
          key={`listing${i}`}
          href={buildListingURL(property, listings[i]._id)}
          target="_blank"
          rel="noopener noreferrer"
          className="listing-card"
          data-aos="fade-up"
          data-aos-once="true"
          data-aos-delay={i * 150}
          data-aos-duration="2000"
        >
          <div className="listing-photo">
            <img src={property.Photos[0]?.url} alt={buildPropertyAddress(property).replace(/-/g, ' ')} />
          </div>
          <div className="listing-details">
            <p className="listing-features mb-0">
              <strong>{pageType === 'sell' ? 'Recently sold home' : 'House for sale'}</strong>
            </p>
            <p className="listing-price">{currencyFormat(property.ListPrice)}</p>
            <p className="listing-features">
              {property.BedroomsTotal} bd | {property.RawMlsBathroomsTotal} ba |{' '}
              {property?.LivingArea ? numeral(property?.LivingArea).format() : '--'} sq ft
            </p>

            <address>
              <p className="fs-5 leading--none mb-1">
                <strong>
                  {property.City
                    ? `${titleCheck(property?.City)}, `
                    : property.CountyOrParish && `${titleCheck(property?.CountyOrParish)}, `}
                  {property?.StateOrProvince && `${upperCheck(property?.StateOrProvince)} `}
                  {property?.PostalCode && `${titleCheck(property?.PostalCode)}`}
                </strong>
              </p>
              <small className="cc-font-weight--normal leading--none">
                {property.StreetNumber && `${property?.StreetNumber} `}
                {property.StreetDirPrefix && `${property.StreetDirPrefix} `}
                {property.StreetName && `${titleCheck(property?.StreetName)}`}
                {property.StreetSuffix && ` ${titleCheck(property.StreetSuffix)}`}
                {property.StreetDirSuffix && ` ${property.StreetDirSuffix}`}
                {property.UnitNumber && ` Unit ${property.UnitNumber}`}
              </small>
            </address>

            <p className="listing-mls">
              Real estate sale listing provided by <br />
              {property?.OriginatingSystemName}
            </p>
          </div>
        </a>
      );

      htmlListings.push(listItem);
    }
  }

  return htmlListings;
}

class ListingsSection extends React.Component {
  state = {
    capcenterListings: [],
    saleText: '',
  };

  async componentDidMount() {
    if (!this.state.initialized) {
      this.setState({ capcenterListings: await renderListings(this.props.pageType) });
      this.setState({ initialized: true });
      this.setState({ saleText: this.props.pageType === 'sell' ? ' sold' : ' for sale' });
    }
  }

  render() {
    return (
      <section className="section-listings">
        <h2>
          Latest{' '}
          <svg className="h-5 capcenter-logo" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#EA701B"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.564 17.82H11.153V14.981L21.825 8.716V0L6 9.086V32.984H21.825V27.068H13.564V17.82Z"
            ></path>
            <path
              fill="#EA701B"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M25.104 0V8.716L35.775 14.981V17.82H33.362V27.068H25.104V32.984H40.926V9.086L25.104 0Z"
            ></path>
          </svg>{' '}
          CapCenter <span className="text-nowrap">homes {this.state.saleText}</span>
        </h2>

        <div className=" rtb-flex">{this.state.capcenterListings}</div>
      </section>
    );
  }
}

export default ListingsSection;
