import { quoteUrl } from '@capcenter/shared'
import County from './interfaces/County'
import getCountiesFromBackupSource from './getCountiesFromBackupSource'
import checkSessionStorage from './checkSessionStorage'

export default async function getCounties(): Promise<Array<County> | string> {
  const url: string = quoteUrl + 'Counties'
  const dataId = 'ccCounties'
  const sessionData = checkSessionStorage(dataId)

  if (sessionData) {
    return sessionData
  } else {
    try {
      //Get Counties (counties in our service area) from our system of record (Clac DB in Azure SQL Instance for now)
      let response = await fetch(url)
      let parsedResponse = await response.json()

      let counties: Array<County> = parsedResponse

      window.sessionStorage.setItem(dataId, JSON.stringify(counties))
      window.sessionStorage.setItem(dataId + 'Date', JSON.stringify(new Date()))
      
      return counties
    }
    catch (err) {
      const error: any = err;
      
      try {
        // Problem getting county data from primary source - let go to our back up source (should be up to date)
        console.error('WARNING! Retrieved counties from secondary source.')
        return getCountiesFromBackupSource()
      }
      catch (err) {
        // Not good - cannot get list of our counties - App fails. 
        // TODO: Send email or text (alert) to admins
        console.error('Error Cannot get counties from primary or secondary source.')
        console.error('Error:', error)
        console.error('Secondary Error:', err)
        return 'Unexpected Counties Database Error. Please try again later.'
      }
    }
  }
}
