const contentClientId = 'dfbc5809-2ac4-4172-8a15-0b4564c3f2d1';
const contentAppId = '10ed6788-ad92-4adb-b32b-526e0fbd74ee';
const graphUrl = 'https://graph.microsoft.com';
const googleMapsKey = 'AIzaSyCdvaMHNx50PAJqYNH4708oC18aPnDdFVA'
const reCaptchaPublicKey = '6Lco39EgAAAAADalBpJiH9lHX69S36pzA7YLSiLX';
const AIKey = '5b17a625-7724-4de2-8157-fb4b018b72ac'

let contentUrl = 'https://ccazimages.blob.core.windows.net/prodcontents/';

// Determine base urls for three environments + localhost (use dev for localhost)
const hostname = window && window.location && window.location.hostname;

const prod = 'www.capcenter.com'
const prodRealEstate = 'www.capcenterhomes.com'
const prodRealty = 'www.capcenterrealty.com'
const prodInsurance = 'www.capcenterinsurance.com'
const qa = 'qa.capcenter.com'
const qaRealEstate = 'qa.capcenterhomes.com'
const qaRealty = 'qa.capcenterrealty.com'
const qaInsurance = 'qa.capcenterinsurance.com'
const dev = 'dev.capcenter.com'
const devRealEstate = 'dev.capcenterhomes.com'
const devRealty = 'dev.capcenterrealty.com'
const devInsurance = 'dev.capcenterinsurance.com'

const dotNetProd = 'apps.capcenter.com'
const dotNetQA = 'apps-qa.capcenter.com'
const dotNetDev = 'apps-dev.capcenter.com'


let baseUrl = 'https://' + prod;
let baseRealEstateUrl = 'https://' + prodRealEstate;
let baseRealtyUrl = 'https://' + prodRealty;
let baseInsuranceUrl = 'https://' + prodInsurance;
let legacyUrl = 'https://' + dotNetProd;

let isLocalHostUrl = false;

if (hostname !== prod && hostname !== prodRealEstate && hostname !== prodRealty && hostname !== prodInsurance && hostname !== dotNetProd && hostname !== 'capcenter.com' && hostname !== 'capcenterhomes.com' && hostname !== 'capcenterrealty.com' && hostname !== 'capcenterinsurance.com') {
	// Dev:
	baseUrl = 'https://' + dev;
	baseRealEstateUrl = 'https://' + devRealEstate;
	baseRealtyUrl = 'https://' + devRealty;
	baseInsuranceUrl = 'https://' + devInsurance;
	legacyUrl = 'https://' + dotNetDev;

	// QA:
	if (hostname === qa || hostname == qaRealEstate || hostname == qaRealty || hostname == qaInsurance || hostname === dotNetQA) {
		baseUrl = 'https://' + qa;
		baseRealEstateUrl = 'https://' + qaRealEstate;
		baseRealtyUrl = 'https://' + qaRealty;
		baseInsuranceUrl = 'https://' + qaInsurance;
		legacyUrl = 'https://' + dotNetQA;  // pointing qa to dev for legacy services for now
	}

	//Enable if you need to test WebFormsAPI locally
	//   - use npm run dev
	
	// const local = 'localhost:3045'
	// if (window.location.hostname.toLocaleLowerCase() === 'localhost') {
	// 	baseUrl = 'http://' + local;
	// 	legacyUrl = 'https://' + dotNetDev;  // pointing qa to dev for legacy services for now
	// 	isLocalHostUrl = true;
	// }
	

	contentUrl = 'https://ccdevdata001.blob.core.windows.net/prodcontents/';
}
const contentHistoryUrl = legacyUrl + '/CMS/api/content/';
const contentFileUrl = legacyUrl + '/cms/api/File/';
const quoteUrl = legacyUrl + '/QuoteService/api/';
const howHearUrl = baseUrl + '/webForms/api/HowHear';
const newAppUrl = baseUrl + '/application/createLoan'
const homeValueUrl = baseUrl + '/home-value-api/'

export const savedSearchApiUrl = `${baseUrl}${isLocalHostUrl ? '/' : '/saved-search/'}`
export const twilioUrl = `${baseUrl}${isLocalHostUrl ? '' : '/twilio'}`

export { contentClientId, contentAppId, graphUrl, googleMapsKey, reCaptchaPublicKey, contentHistoryUrl, contentFileUrl, contentUrl, quoteUrl, AIKey, baseUrl, baseRealEstateUrl, baseRealtyUrl, baseInsuranceUrl, howHearUrl, newAppUrl, homeValueUrl, legacyUrl }