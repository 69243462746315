/*global google*/
import React from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Tooltip from '../Tooltip';
import Shadows from '../../constants/Shadows';
import Button from '../Button';
import Icon from '../Icon';
import { googleMapsKey, Colors } from '@capcenter/shared';
import { getCounties, getStates } from '@capcenter/shared';
import GImage from './google-logo.png';

//import numeral from 'numeral';

const RenderFooter = () => (
  <div className="suggestion-item" style={{ float: 'right' }}>
    <img src={GImage} alt="powered by Google" style={{ width: 144, padding: 5 }} />
  </div>
);

class AddressSearch extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      address: null,
      addressInvalid: '',
      prevAddress: null,
      gpsError: '',
      error: false,
      result: '',
      realty: false,
    };

    this.randomId = Math.floor(Math.random() * 90000) + 10000;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.google !== false) {
      const existingGoogleMapScript = document.getElementById('AddressSearchGoogleMaps');

      if (existingGoogleMapScript) {
        existingGoogleMapScript.addEventListener('load', () => {
          this.forceUpdate();
        });
      } else {
        // Load Google Maps
        const googleMapScript = document.createElement('script');
        googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsKey}&libraries=places`;
        googleMapScript.id = 'AddressSearchGoogleMaps';
        window.document.body.appendChild(googleMapScript);

        googleMapScript.addEventListener('load', () => {
          this.forceUpdate();
        });
      }
    }

    getCounties().then(counties => {
      if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && this.props.log === true) {
        console.log('Counties', counties);
      }

      if (typeof counties !== 'string') {
        getStates().then(states => {
          if ((!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && this.props.log === true) {
            console.log('States', states);
          }

          if (typeof states !== 'string') {
            if (this._isMounted) {
              this.setState({ counties, states });
            }

            if (this.props.address !== undefined) {
              this.handleSelect(this.props.address, false);
            }
          } else {
            this.setError(states);
          }
        });
      } else {
        this.setError(counties);
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.address !== this.props.address && this.props.address !== this.state.address) {
      this.handleSelect(this.props.address, false);
    }
  }

  debugCounties = counties => {
    // FIND PROBLEM COUNTIES

    let countyMatch = [];

    counties.map((location, i) => {
      const thisLocation = { terms: [{ value: location.CountyName }, { value: location.State }] };

      setTimeout(() => {
        this.onSelect(thisLocation).then(result => {
          //console.log(result)

          let newLocation = location;

          if (result.isMatched !== undefined) {
            newLocation.GoogleMatch = result.isMatched;
            newLocation.GoogleCounty = result.county;
            newLocation.GoogleType = result.type;
          }

          countyMatch.push(newLocation);
        });

        /*
          if(i === counties.length -1){
            console.log(countyMatch, JSON.stringify(countyMatch))
          }	
*/
      }, 1000 * i);

      return null;
    });
  };

  getGoogleCounty = results => {
    let filtered_array = results[0].address_components.filter(function (address_component) {
      return address_component.types.includes('administrative_area_level_2');
    });
    return filtered_array.length ? filtered_array[0].long_name : null;
  };

  getGoogleCity = results => {
    let filtered_array = results[0].address_components.filter(function (address_component) {
      return address_component.types.includes('locality');
    });
    return filtered_array.length ? filtered_array[0].long_name : null;
  };

  getGoogleState = results => {
    let filtered_array = results[0].address_components.filter(function (address_component) {
      return address_component.types.includes('administrative_area_level_1');
    });
    return filtered_array.length ? filtered_array[0].short_name : null;
  };

  getGoogleZipCode = results => {
    let filtered_array = results[0].address_components.filter(function (address_component) {
      return address_component.types.includes('postal_code');
    });
    return filtered_array.length ? filtered_array[0].short_name : null;
  };

  handleSelect = (inputtedAddress, tooltip, secondTry) => {
    if (!inputtedAddress) {
      return null;
    } else if (typeof google !== 'undefined' &&  google.maps && this.state?.counties) {
      return geocodeByAddress(inputtedAddress)
        .then(results => {
          // Filters out unused information such as premise/neighborhood (breaks the address parser in API)
          let formattedAddress = results[0].formatted_address;
            try {
              // Attempt to piece together the address but if that fails use the formatted string provided
              if (results[0].address_components.find(component => component.types.includes('premise')) !== undefined)
              {
                formattedAddress =
                  results[0].address_components.find(component => component.types.includes('street_number')).long_name + ' ' +
                  results[0].address_components.find(component => component.types.includes('street_address') || component.types.includes('route') || component.types.includes('intersection')).long_name +
                  ( results[0].address_components.find(component => component.types.includes('subpremise')) ? ' ' + results[0].address_components.find(component => component.types.includes('subpremise')).long_name : '') +
                  ', ' + results[0].address_components.find(component => component.types.includes('locality') || component.types.includes('sublocality'),).long_name + ', ' +
                  results[0].address_components.find(component => component.types.includes('administrative_area_level_1')).short_name + ' ' +
                  results[0].address_components.find(component => component.types.includes('postal_code')).long_name;
              }
            } catch {
              formattedAddress = results[0].formatted_address;
            }

          const state = this.getGoogleState(results);
          const zipCode = this.getGoogleZipCode(results);
          let county = this.getGoogleCounty(results);
          let countyData = {};
          let countiesCheck = false;
          let countyStripped = false;

          // Remove "County" from Google result to match our DB
          if (county !== null && county.includes(' County')) {
            county = county.substring(0, county.length - 7);
            countyStripped = true;
          }

          // If no county, check cities
          if (county === null) {
            county = this.getGoogleCity(results);
          }

          //console.log(county, county + (countyStripped ? " County":""))

          // If Google is working
          if (county !== null) {
            // Find matches in DB
            const countyData1 = this.matchCounty(county, state, countyStripped);

            // No match? check cities again.
            if (countyData1.countyId === null) {
              countyStripped = false;

              county = this.getGoogleCity(results);

              if (county !== null) {
                const countyData2 = this.matchCounty(county, state, countyStripped);

                // Still nothing? Reject user query
                if (countyData2.countyId === null) {
                  //console.log(formattedAddress, results[0].formatted_address)

                  if (secondTry !== true) {
                    // Try searching by formatted address
                    this.handleSelect(results[0].formatted_address, true, true);

                    return null;
                  } else {
                    countiesCheck = false;
                  }
                } else {
                  countyData = countyData2;

                  countiesCheck = true;
                }
              } else {
                countiesCheck = false;
              }
            } else {
              countyData = countyData1;

              countiesCheck = true;
            }
          }

          if (countiesCheck) {
            if (countyStripped) {
              countyData.county = county + ' County';
            }

            if (zipCode) {
              countyData.zipCode = zipCode;
            }

            this.completeSelect(formattedAddress, countyData, results, tooltip);

            return null;
          } else {
            const stateData = this.matchCounty('', state, '');

            if (stateData.state !== null) {
              this.setError('Try a more exact address. No matching county found for ' + formattedAddress + '.');
            } else {
              if (this.props.realtyWarning || this.props.serviceAreaError) {
                this.setError("We don't service " + formattedAddress);
              } else {
                this.completeSelect(formattedAddress, {}, results, false);
              }
            }

            return null;
          }
        })
        .catch(err => {
          if (console.error && err) {
            console.error(err);
          } else {
            console.log('Geocode error');
          }
        });
    } else {
      let self = this;
      setTimeout(function () {
        self.handleSelect(inputtedAddress, tooltip, secondTry);
      }, 200);
    }
  };

  completeSelect = (suggestion, countyData, results, tooltip) => {
    if (this._isMounted) {
      this.setState({ address: suggestion, addressInvalid: null, realty: countyData.realtyActive });
    }

    if (this.props.setCounty !== undefined) {
      this.props.setCounty(countyData);
    }

    if (this.props.setAddress !== undefined) {
      this.props.setAddress(suggestion);
    }

    if (this.props.setAllData !== undefined) {
      const geocode = results[0];

      this.props.setAllData({
        suggestion: suggestion,
        state: countyData.stateData,
        county: countyData.countyData,
        components: geocode,
      });
    }

    if (tooltip !== false) {
      this.setResult(
        this.props.success
          ? this.props.success(countyData)
          : 'County Match: ' + countyData.county + ', ' + countyData.state,
      ); // + ": $" ) // + numeral(jumboMin).format("0,0"))
      this.addressSearcher.blur();
    }
    document.getElementById('address-searcher' + this.randomId).blur();
  };

  matchCounty = (county, state, countyStripped) => {
    let countyId = null;
    let stateId = null;
    let stateAbbreviation = null;
    let jumboMin = null;
    let realtyActive = null;
    let region = null;
    let countyData = null;
    let stateData = null;

    //console.log(county, this.state.counties)

    this.state?.counties && this.state.counties.map((location, i) => {
      let tryStripping = false;

      // If our DB has "County" add it back in
      if (location.CountyName.includes('County') && countyStripped) {
        county = county + ' County';
        tryStripping = true;
      }

      // Richmond over-ride
      let countyName = location.CountyName.toLowerCase();
      if (countyName === 'richmond city') {
        countyName = 'richmond';
      }

      if (countyName === county.toLowerCase() && location.State === state) {
        countyData = location;
        countyId = location.CountyId;
        jumboMin = location.AmountLimit;
        realtyActive = location.IsRealtyApplied;
        region = location.region;
      } else {
        if (tryStripping === true) {
          county = county.substring(0, county.length - 7);
        }
      }

      if (location.State === state) {
        //console.log(location)

        this.state.states.map((thisStateData, i) => {
          //console.log(stateData.StateAbbreviation, state)

          if (thisStateData.StateAbbreviation === state) {
            stateData = thisStateData;
            stateId = thisStateData.StateId;
            stateAbbreviation = thisStateData.StateAbbreviation;
          }

          return null;
        });
      }

      return { countyId, stateId, jumboMin, realtyActive, county, region };
    });

    //console.log(countyId, jumboMin, realtyActive, county, stateAbbreviation, stateId)

    return {
      countyId,
      stateId,
      jumboMin,
      realtyActive,
      county,
      region,
      state: stateAbbreviation,
      countyData,
      stateData,
    };
  };

  geolocate = () => {
    if (typeof google !== 'undefined' && google.maps) {
      const geocoder = new google.maps.Geocoder();

      const handleSelect = this.handleSelect;
      const setGpsError = this.setGpsError;

      const success = data => {
        //console.log(data, data.Geoposition, data.coords.latitude, data.coords.longitude)

        const latlng = { lat: data.coords.latitude, lng: data.coords.longitude };

        //console.log(latlng);

        geocoder.geocode({ location: latlng }, function (results, status) {
          if (status === 'OK') {
            if (results[0]) {
              //console.log(results)

              results.find(r => r.types[0] === 'administrative_area_level_2') ?
                handleSelect(results.find(r => r.types[0] === 'administrative_area_level_2').formatted_address) :
                setGpsError('No results found');
            } else {
              setGpsError('No results found');
            }
          } else {
            setGpsError('Geocoder failed due to: ' + status);
          }
        });
      };

      const error = e => setGpsError(e.message);

      navigator.geolocation.getCurrentPosition(success, error);
    }
  };

  setResult = result => {
    const resultLength = result.toString().length;
    const holdMultiplier = 50;
    const holdLength = resultLength * holdMultiplier + 400;

    this.setState({ result });
    setTimeout(() => {
      this.setState({ result: '', error: false });
    }, holdLength);
  };

  handleChange = addressInvalid => {
    if (this._isMounted) {
      this.setState({ addressInvalid });
    }
  };

  setError = error => {
    const tipLength = error.toString().length;
    const holdMultiplier = 50;
    const holdLength = tipLength * holdMultiplier + 400;

    if (this.addressSearcher) {
      this.addressSearcher.blur();
    }
    if (this._isMounted) {
      this.setState({ error, address: null, addressInvalid: this.props.address ? this.props.address : '' });
      setTimeout(() => {
        this.setState({ result: '', error: false });
      }, holdLength);
    }
  };

  setGpsError = gpsError => {
    if (this._isMounted) {
      this.setState({ gpsError });
      setTimeout(() => {
        this.setState({ gpsError: '' });
      }, 2000);
    }
  };

  clearInput = () => {
    //console.log("clear",this.state.address, this.state.addressInvalid
    if (document.getElementById('autocomplete-dropdown-container' + this.randomId)) {
      document.getElementById('autocomplete-dropdown-container' + this.randomId).style.display = 'block';
    }
    if (this.state.address !== '') {
      this.setState({ address: '', prevAddress: this.state.address });
    }
  };

  restore = () => {
    if (this.state.address === '') {
      this.setState({
        address: this.state.prevAddress !== null ? this.state.prevAddress : null,
        addressInvalid: this.state.prevAddress !== null ? null : '',
      });
    }
  };

  findSuggestion = suggestions => {
    let selection;

    suggestions.map((suggestion, i) => {
      if (suggestion.active) {
        selection = suggestion.description;
      }

      return null;
    });
    if (!selection) {
      selection = suggestions[0].description;
    }
    this.handleSelect(selection);
  };

  render() {
    let searchOptions = {};

    if (typeof google !== 'undefined' && google.maps) {
      searchOptions = this.props.searchOptions ? this.props.searchOptions : {};

      if (this.props.localSearch) {
        searchOptions.location = new google.maps.LatLng(37.5407246, -77.4360481);
        searchOptions.radius = 500000;
      }
    }

    return (
      <Tooltip
        arrow
        distance={10}
        html={
          this.state.result !== '' ? (
            this.state.result
          ) : this.state.addressInvalid === null ? (
            this.props.notice
          ) : this.state.error !== false ? (
            <div />
          ) : undefined
        }
        error={this.state.error !== false}
        errorHtml={this.state.error}
        open={this.state.address === '' || this.state.result !== '' || this.state.error !== false ? true : false}
      >
        <div>
          {typeof google !== 'undefined' && google.maps && this.state.address !== undefined ? (
            <PlacesAutocomplete
              value={this.state.addressInvalid === null ? this.state.address : this.state.addressInvalid}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              highlightFirstSuggestion={true}
              searchOptions={{ ...searchOptions }}          
            >
              {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                return (
                  <div style={{ position: 'relative' }}>
                    <div
                      className="input-group"
                      style={{ paddingBottom: this.props.noPadding !== true ? 10 : 0, display: 'flex' }}
                    >
                      <div
                        className="input-group-prepend input-group-addon"
                        onClick={() => {
                          this.clearInput();
                          this.addressSearcher.focus();
                        }}
                        style={{
                          padding: 0,
                          width: 'auto',
                          borderRadius: '.25rem',
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <div
                          className="input-group-text"
                          style={{
                            backgroundColor: this.props.disabled ? Colors.gray350 : Colors.white100,
                            cursor: 'text',
                            padding: 11,
                            borderTopLeftRadius: '.25rem',
                            borderColor: this.props.invalid ? Colors.brightRed : '#647778',
                          }}
                        >
                          <Icon icon="search" />
                        </div>
                      </div>
                      <input
                        id={'address-searcher' + this.randomId}
                        ref={component => {
                          this.addressSearcher = component;
                        }}
                        onClick={this.clearInput}
                        onSelect={this.clearInput}
                        autoComplete="chrome-off"
                        name={this.props.name}
                        {...getInputProps({
                          autoComplete: "chrome-off",
                          disabled: this.props.disabled,
                          placeholder: (this.props.placeholder ? this.props.placeholder : 'Search Addresses '),
                          className:
                            'location-search-input form-control bold ' +
                            ((this.props.invalid === true &&
                              (this.state.addressInvalid === '' || this.props.invalidCheck)) ?
                              'is-invalid':
                              'border-secondary'),    
                          onBlur: e => {
                            if (document.getElementById('autocomplete-dropdown-container' + this.randomId)) {
                              document.getElementById('autocomplete-dropdown-container' + this.randomId).style.display = 'none';
                            }
                            suggestions.length !== 0
                              ? this.findSuggestion(suggestions)
                              : this.restore()
                          },
                          style: { minHeight: 40, borderLeft: 0},
                        })}
                      />
                      {this.props.geolocate && (
                        <div className="input-group-append">
                          <Tooltip
                            arrow
                            html="Find my location."
                            error={true}
                            errorHtml={this.state.gpsError}
                            open={this.state.gpsError !== ''}
                            style={{ display: 'flex' }}
                          >
                            <Button
                              tabIndex={0}
                              className="btn-geolocate"
                              id={'geolocate-button' + this.randomId}
                              text=""
                              disabled={this.props.disabled}
                              icon={this.state.gpsError === '' ? ['icon', 'gps'] : ['icon', 'gps-error']}
                              onClick={this.props.disabled ? false : this.geolocate}
                              textColor={this.state.gpsError === '' ? Colors.black500 : Colors.red500}
                              color={this.props.disabled ? Colors.gray400 : Colors.white100}
                              borderColor={this.props.invalid ? Colors.brightRed : Colors.gray450}
                              light={this.state.gpsError === '' ? true : false}
                              style={{
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                fontSize: 'inherit',
                                minHeight: 40,
                                padding: '7px 12px'
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </div>
                    <div
                      className="autocomplete-dropdown-container"
                      id={'autocomplete-dropdown-container' + this.randomId}
                      style={{
                        display: this.state.address === '' ? 'block' : 'none',
                        width: '100%',
                        backgroundColor: Colors.white100,
                        padding: 10,
                        position: 'absolute',
                        top: 40,
                        left: 0,
                        zIndex: 1045,
                        fontSize: 'inherit',
                        boxShadow: Shadows.dropdown,
                      }}
                    >
                      {suggestions.map((suggestion, i) => {
                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                          ? { backgroundColor: Colors.gray350, cursor: 'pointer', padding: 5 }
                          : { backgroundColor: Colors.white100, cursor: 'pointer', padding: 5 };
                        return (
                          <div {...getSuggestionItemProps(suggestion, { className, style })} key={suggestion.placeId}>
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                      <RenderFooter />
                    </div>
                  </div>
                );
              }}
            </PlacesAutocomplete>
          ) : (
            <div
              className="input-group"
              style={{ paddingBottom: this.props.noPadding !== true ? 10 : 0, display: 'flex' }}
            >
              <div
                className="input-group-prepend input-group-addon"
                style={{
                  backgroundColor: Colors.gray350,
                  padding: 0,
                  width: 'auto',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                <div
                  className="input-group-text"
                  style={{
                    backgroundColor: Colors.gray350,
                    cursor: 'not-allowed',
                    padding: 11,
                    borderTopLeftRadius: 4,
                  }}
                >
                  <Icon icon="search" />
                </div>
              </div>

              <input
                id={'address-searcher' + this.randomId}
                disabled
                style={{ minHeight: 40, borderLeft: 0, cursor: 'not-allowed' }}
                placeholder={(this.props.placeholder ? this.props.placeholder : 'Addresses ') + ' ...'}
                className="location-search-input form-control bold"
              />
              {this.props.geolocate && (
                <div className="input-group-append">
                  <Button
                    disabled
                    className="btn-geolocate"
                    id={'geolocate-button' + this.randomId}
                    text=""
                    icon={['icon', 'gps']}
                    textColor={Colors.black500}
                    color={Colors.gray400}
                    borderColor={Colors.gray450}
                    light={true}
                    style={{
                      borderTopLeftRadius: 0,
                      borderBottomLeftRadius: 0,
                      fontSize: 'inherit',
                      minHeight: 40,
                      padding: '7px 12px',
                    }}
                  />
                </div>
              )}
            </div>
          )}

          {this.props.realtyWarning && this.state.realty === false && this.state.address && (
            <div className="alert alert-warning" role="alert">
              We don&apos;t currently offer <strong>Realty Services</strong> in your area ({this.state.address}), but
              please feel free to submit this form. We&apos;re always exploring expanding our realty services to new
              areas.
            </div>
          )}
        </div>
      </Tooltip>
    );
  }
}

export default AddressSearch;
